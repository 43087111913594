/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../../components/Layout'
import ThemeWrapper from '../../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, PriceWrap, Button } from '@swp/components'
import SiteHeader from '../../components/hr/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Ceník"}>
        <SiteHeader />

        <Column className="mt--30 pb--0 pt--0" name={"pricelist"}>
          
          <ColumnWrap className="column__flex --shape4 --center el--2 pb--60 pl--0 pr--0 pt--50 flex--top" anim={null} animS={null} style={{"backgroundColor":"var(--color-custom-1)"}} columns={"2"}>
            
            <ColumnWrapper className="js-anim  --anim5 --anim-s3" anim={"5"} animS={"3"} style={{"maxWidth":510}}>
              
              <Title className="title-box title-box--left fs--62 title-box--invert" content={"Pricelist"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper anim={null} animS={null} style={{"maxWidth":510}}>
              
              <Text className="text-box text-box--left text-box--invert pl--06" content={"Click and start writing. This is a text area. If needed, the color, font size, line spacing, text alignment and even the <strong>maximum width of a block of text</strong> may be customized.  If you wish to find out more about editing, click <a href=\"https://saywebpage.com/cs/video-pruvodce-editaci/\">here</a>."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--80 pt--80" name={"pricelist-2"}>
          
          <ColumnWrap className="column__flex el--2 pb--06 pl--02 pr--02 pt--06" style={{"maxWidth":1400}} columns={"2"}>
            
            <ColumnWrapper className="js-anim  --anim5 --anim-s3" anim={"5"} animS={"3"}>
              
              <Title className="title-box" content={"Úklid obytních prostor"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="pb--0 pt--0">
              
              <PriceWrap className="pb--08 pl--16 pr--16 pt--08" style={{"backgroundColor":"var(--color-blend--95)"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box w--400" content={""}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box w--500" content={"$17"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap className="pb--08 pl--16 pr--16 pt--08" style={{"backgroundColor":"var(--color-blend--95)"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box w--400" content={""}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box w--500" content={"$17"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap className="pb--08 pl--16 pr--16 pt--08" style={{"backgroundColor":"var(--color-blend--95)"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box w--400" content={""}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box w--500" content={"$17"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap className="pb--08 pl--16 pr--16 pt--08" style={{"backgroundColor":"var(--color-blend--95)"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box w--400" content={""}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box w--500" content={"$17"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap className="pb--08 pl--16 pr--16 pt--08" style={{"backgroundColor":"var(--color-blend--95)"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box w--400" content={""}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box w--500" content={"$17"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex el--2 mt--60 pb--06 pl--02 pr--02 pt--06" style={{"maxWidth":1400}} columns={"2"}>
            
            <ColumnWrapper className="js-anim  --anim5 --anim-s3" anim={"5"} animS={"3"}>
              
              <Title className="title-box" content={"Úklid kancelářských prostor"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="pb--0 pt--0">
              
              <PriceWrap className="pb--08 pl--16 pr--16 pt--08" style={{"backgroundColor":"var(--color-blend--95)"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box w--400" content={""}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box w--500" content={"$17"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap className="pb--08 pl--16 pr--16 pt--08" style={{"backgroundColor":"var(--color-blend--95)"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box w--400" content={""}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box w--500" content={"$17"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap className="pb--08 pl--16 pr--16 pt--08" style={{"backgroundColor":"var(--color-blend--95)"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box w--400" content={""}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box w--500" content={"$17"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap className="pb--08 pl--16 pr--16 pt--08" style={{"backgroundColor":"var(--color-blend--95)"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box w--400" content={""}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box w--500" content={"$17"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap className="pb--08 pl--16 pr--16 pt--08" style={{"backgroundColor":"var(--color-blend--95)"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box w--400" content={""}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box w--500" content={"$17"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--80 pt--0" name={"lw60z5h5vx"}>
          
          <ColumnWrap className="column__flex --shape4 --center el--2 pb--50 pl--0 pr--0 pt--50 flex--top" anim={null} animS={null} style={{"backgroundColor":"var(--color-blend--95)"}} columns={"2"}>
            
            <ColumnWrapper className="js-anim  --anim5 --anim-s3" anim={"5"} animS={"3"} style={{"maxWidth":510}}>
              
              <Title className="title-box title-box--left" content={""}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper anim={null} animS={null} style={{"maxWidth":510}}>
              
              <Button className="btn-box btn-box--shape3 btn-box--right" href={"/hr/kontakt"} content={"Contact me"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"footer"} style={{"backgroundColor":"var(--color-custom-1)"}}>
          
          <ColumnWrap className="column__flex el--2 mb--20 mt--10 pb--06 pl--02 pr--02 pt--06" style={{"maxWidth":1400}} columns={"2"}>
            
            <ColumnWrapper className="--left" style={{"maxWidth":""}}>
              
              <Title className="title-box fs--24 title-box--invert" style={{"maxWidth":""}} content={""}>
              </Title>

              <Text className="text-box text-box--invert" content={"Click and start writing. This is a text area. If needed, the color, font size, line spacing, text alignment and even the <strong>maximum width of a block of text</strong> may be customized.  If you wish to find out more about editing, click <a href=\"https://saywebpage.com/cs/video-pruvodce-editaci/\">here</a>."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--right" style={{"maxWidth":""}}>
              
              <Title className="title-box fs--24 title-box--invert" style={{"maxWidth":""}} content={"Contact"}>
              </Title>

              <Text className="text-box text-box--invert" content={"510-851-4014<br>info@vase-stranky.com"}>
              </Text>

              <Text className="text-box text-box--invert" content={"<a href=\"https://saywebpage.com\">Create your own website</a>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}